@import "~@fider/assets/styles/variables.scss";

.l-post-filter {
  vertical-align: middle;

  .c-dropdown-menu {
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
  }

  .counter {
    font-size: 8px;
    background-color: $gray-lighter;
    color: $gray-darkest;
    display: inline-block;
    min-width: 15px;
    padding: 0px 5px;
    margin-left: 5px;
    text-align: center;
    border-radius: 10px;
    vertical-align: top;
    font-weight: 600;
  }
}
