@import "~@fider/assets/styles/variables.scss";

.c-post-list {
  .c-list-item {
    display: flex;
    border-top-width: 0.1px !important;

    .c-list-item-title {
      color: $gray-darkest;
      font-weight: 500;
      display: block;
      max-width: 85%;
      margin-bottom: 10px;
      font-size: $font-size-large;
      &:hover {
        color: $main-color;
      }
    }

    .c-list-item-content {
      width: calc(100% - 55px);
      display: block;

      .c-tag {
        margin: 0 3px 3px 0;
      }

      svg.icon {
        vertical-align: middle;
      }
    }

    .c-list-item-description {
      color: $gray-darker;
      min-height: 20px;
      margin-bottom: 10px;
    }
  }
}

.c-post-list-show-more {
  cursor: pointer;
  font-weight: 500;
  color: $main-color;
  margin-left: 55px;
}
